import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"


const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
        <Seo title="Focus Window Recorder Privacy" />
        <h2>Privacy Policy for Screen Recording App:</h2>
        
<p>
We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose information when you use our screen recording app.
</p>

<p>
<b>Collection of Information:</b>
We do not collect any personal information from our users. Our screen recording app does not track users and only saves recorded videos locally on the device.
</p>

<p>
<b>Use of Information:</b>
The recorded videos are saved locally on the device and are not shared with any third-party services. Our screen recording app does not use any data for marketing purposes or any other purposes outside of the app's intended functionality.
</p>

<p>
<b>Disclosure of Information:</b>
We do not disclose any personal information to third parties. The recorded videos are saved locally on the device and are not shared with any third-party services.
</p>

<p>
<b>Data Security:</b>
We take appropriate measures to secure the data saved locally on the device. Our screen recording app does not transmit data over the internet, and all recorded videos are stored locally on the device.
</p>

<p>
<b>Children's Privacy:</b>
Our screen recording app is not intended for use by children under the age of 13. We do not knowingly collect any personal information from children under the age of 13.
</p>

<p>
<b>Changes to the Privacy Policy:</b>
We may update our Privacy Policy from time to time. Any changes will be posted on this page, and users will be notified of any significant changes.
</p>

<p>
<b>Contact Us:</b>
If you have any questions about our Privacy Policy or the practices of our screen recording app, please contact us at antran_apps_at_gmail_dot_com.
</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
